var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "header" } }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("router-link", { attrs: { to: "/" } }, [
          _c("img", {
            staticClass: "logo",
            attrs: { src: "/assets/img/questicals.svg" }
          })
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }